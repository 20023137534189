import * as React from 'react';
//import Button from '@mui/material/Button';
//import Box from '@mui/material/Box';

const Home = () => {
  function sayHello() {
    alert('You clicked me!');
  }

  return <div id="home_h1">
          <h1>Home</h1>
          <br />
          <a target="_blank" href="https://www.amazon.com/Super-Funny-Variety-Warning-Sticker/dp/B09NLV2M2F/ref=sr_1_7?crid=2BYTREK7V5WSG&amp;keywords=gag+gift+sticker&amp;qid=1686104024&amp;sprefix=gag+gift+sticker%252Caps%252C107&amp;sr=8-7&_encoding=UTF8&tag=gaggiftgivers-20&linkCode=ur2&linkId=c4199891e702f564accb3836d0866ef0&camp=1789&creative=9325">GagGiftStickers</a>
          <br />
          <br />
          <a target="_blank" href="https://www.amazon.com/dp/B0C4FQMR68/ref=sspa_dk_detail_2?ie=UTF8&amp;psc=1&amp;pd_rd_i=&amp;pd_rd_i=B0C4FQMR68p13NParams&amp;s=kitchen&amp;sp_csd=d2lkZ2V0TmFtZT1zcF9kZXRhaWxfdGhlbWF0aWM&_encoding=UTF8&tag=gaggiftgivers-20&linkCode=ur2&linkId=5e9cad46353a7f74e4aab8152758b2bc&camp=1789&creative=9325">NeedToPoo</a>  
          <br />
          <br />
          <button onClick={sayHello}>Default</button>
        </div>;
  };

  export default Home;  

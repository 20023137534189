import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <nav>
        <ul>
          <li>
            <Link class="icon" to="/">9SP</Link>
          </li>
          <li>
            <Link class="link" to="/">Home</Link>
          </li>
          <li>
            <Link class="link" to="/forum">Forum</Link>
          </li>
          <li>
            <Link class="link" to="/download">Download</Link>
          </li>
          <li>
            <Link class="link"to="/contact">Contact</Link>
          </li>
        </ul>
        <hr />
      </nav>
      <Outlet />
    </>
  )
};

export default Layout;

import * as React from 'react';

const Forum = () => {
  const [isOpen, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <h1> Forum </h1>
      <Button onClick={handleOpen}>Open</Button>
      <Button onClick={handleClose}>Close</Button>

      {isOpen && <div>Content</div>}
    </div>
  );
};

const Button = ({ type = 'button', disabled, onClick, children }) => {
  return (
    <button type={type} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};

  export default Forum;
  
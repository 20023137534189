import React from 'react';

const Contact = () => {
  const [isOpen, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <div>
      <h1> Contact </h1>
      <Button onClick={handleClick}>Toggle</Button>

      {isOpen && <div>Content</div>}
    </div>
  );
};

const Button = ({ onClick, children }) => {
  return (
    <button type="button" onClick={onClick}>
      {children}
    </button>
  );
};
  
  export default Contact;
  